<template>
  <v-row>
    <v-col cols="12">
      <v-card class="p-1 pt-2">
        <v-row>
          <v-col cols="8">
            <p class="mx-4 mb-0 f-sanspro fs-11pt fw-600">
              Trilha de atividades
            </p>
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn class="p-1 me-2 text-nonecase" :color="hexShades(cor.active.P,.3)" outlined x-small @click="$refs.formatividaderef.show()">
              <i v-i:duo#plus-circle#20 class="btn-icon-left"></i>
              adicionar atividade
            </v-btn>
          </v-col>
        </v-row>
        <hr class="mt-1 mb-2 me-2 ms-2">
        <v-alert class="mx-3 mt-3" :color="cor.active.S" type="info" dense>
          <p class="m-0 p-0">Nenhuma atividade encontrada!</p>
        </v-alert>
        <itematividade :atividade="atividade" v-for="(atividade,key,index) in atividades" :key="index" @excluir="excluir" @editar="editar"/>
      </v-card>
    </v-col>
    <formatividade ref="formatividaderef" @update="update"/>
    <promptdialog ref="promptdialogref" @confirm="confirmaRemover" />
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray, snapshotValToArraySort } from '@/firebasedb.js';
import itematividade from "./itematividade.vue"
import formatividade from "./formatividade.vue"
import promptdialog from "./promptdialog.vue";

export default {
  name: "listaatividades",
  components: { itematividade, formatividade, promptdialog },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      atividades: {},
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/atividades/').on('value',function(snapshot) {
      self.atividades = snapshotValToArraySort(snapshot.val(),"dataInicio");
      console.log("self.atividades",self.atividades);
      self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    },

    excluir(atividade) {
      console.log("excluir",atividade);
      this.$refs.promptdialogref.show(atividade);
    },

    confirmaRemover(atividade) {
      console.log("confirmaRemover",atividade);
      var updates = {};
      updates["/curriculo/avaliacao/2021S2/atividades/"+atividade.id] = null;
      console.log(updates);
      rdb.ref().update(updates);
      this.toastS("Item removido com sucesso!");
      console.log("agora já elvis!");
    },

    editar(atividade) {
      console.log("editar",atividade);
      this.$refs.formatividaderef.show(atividade);
    },

    update(dados) {
      var self = this;
      var updates = {};
      console.log("update",dados);
      var elem = clone2(dados.formData);
      if(dados.tipo == "Atualizar") {
        updates["/curriculo/avaliacao/2021S2/atividades/"+elem.id] = elem;
      } else {
        var newKey = rdb.ref("/curriculo/avaliacao/2021S2/atividades/").push().key;
        elem.id = newKey;
        elem.createdAt = moment().format();
        updates["/curriculo/avaliacao/2021S2/atividades/"+newKey] = elem;
      }
      console.log("updates",updates);
      rdb.ref().update(updates);
      self.toastS("Atividade cadastrada com sucesso!");
    }
  }
}
</script>

<style scoped>
</style>
