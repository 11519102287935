<template>
  <div class="pt-2 ps-0 pe-4">
    <p class="mx-4 mb-0 f-sanspro fs-11pt fw-600">
      Trilha de atividades
    </p>
    <hr class="mt-1 mb-2 me-2 ms-2">
    <v-timeline class="" align-top dense v-c:K :color="cor.active.K" :dark="isDark">

      <div class="" v-for="(atividade,key,index) in atividades" :key="index">
        <v-timeline-item fill-dot :color="cor.active.S" v-c:K>
          <template v-slot:icon>
            <v-avatar :color="hexTints(cor.active.V,0.4)" v-if="atividade.status=='em andamento'">
              <span v-i:duo#calendar#24></span>
            </v-avatar>
            <v-avatar :color="hexTints(cor.active.S,0.2)" v-if="atividade.status=='não iniciado'">
              <span v-i:duo#calendar#26></span>
            </v-avatar>
            <v-avatar :color="hexShades(cor.active.D,0.3)" v-if="atividade.status=='finalizado'">
              <span v-i:duo#calendar#24 v-c:B></span>
            </v-avatar>
          </template>
          <v-row class="pt-1">
            <v-col cols="12" md="12" class="pt0 mt0">
              <p class="my-0 py-0 f-lato fs-10pt fw-500">
                <span v-i:ic#task#16 class="left py-0"></span>
                <span class="left py-0 mt-1 ms-1">Atividade:</span>
                <v-btn class="ms-3 px-1 text-nonecase" outlined color="" text x-small @click="$refs.atividadetimelinedescrref.show(atividade.nome,atividade.descricao)">
                  <i class="btn-icon-left" v-i:duo#eye#16></i>
                  <span class="f-sanspro">ver descrição</span>
                </v-btn>
              </p>
              <p class="clear my-0 py-0 f-raleway fs-10pt fw-500">
                {{atividade.nome}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:D v-if="atividade.status=='finalizado'">
                <span>Finalizado em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:V v-if="atividade.status=='em andamento'">
                <span>Iniciado em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:I v-if="atividade.status=='não iniciado'">
                <span>Início em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
            </v-col>
          </v-row>
        </v-timeline-item>
      </div>

      <v-timeline-item class="mt-n4 pt-0" fill-dot large :color="cor.active.b" v-c:K>
        <template v-slot:icon>
          <v-avatar>
            <span v-c:I v-i:ic#lock#30></span>
          </v-avatar>
        </template>
        <v-row class="pt-1">
          <v-col cols="12" class="">
            <v-divider class="mt2"></v-divider>
          </v-col>
        </v-row>
      </v-timeline-item>

      <div class="" v-for="(atividade,key,index) in atividadesBlock" :key="index">
        <v-timeline-item fill-dot :color="cor.active.S" v-c:K>
          <template v-slot:icon>
            <v-avatar :color="hexTints(cor.active.V,0.4)" v-if="atividade.status=='em andamento'">
              <span v-i:duo#calendar#24></span>
            </v-avatar>
            <v-avatar :color="hexTints(cor.active.S,0.2)" v-if="atividade.status=='não iniciado'">
              <span v-i:duo#calendar#26></span>
            </v-avatar>
            <v-avatar :color="hexShades(cor.active.D,0.3)" v-if="atividade.status=='finalizado'">
              <span v-i:duo#calendar#24 v-c:B></span>
            </v-avatar>
          </template>
          <v-row class="pt-1">
            <v-col cols="12" md="12" class="pt0 mt0">
              <p class="my-0 py-0 f-lato fs-10pt fw-500">
                <span v-i:ic#task#16 class="left py-0"></span>
                <span class="left py-0 mt-1 ms-1">Atividade:</span>
                <v-btn class="ms-3 px-1 text-nonecase" outlined color="" text x-small @click="$refs.atividadetimelinedescrref.show(atividade.nome,atividade.descricao)">
                  <i class="btn-icon-left" v-i:duo#eye#16></i>
                  <span class="f-sanspro">ver descrição</span>
                </v-btn>
              </p>
              <p class="clear my-0 py-0 f-raleway fs-10pt fw-500">
                {{atividade.nome}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:D v-if="atividade.status=='finalizado'">
                <span>Finalizado em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:V v-if="atividade.status=='em andamento'">
                <span>Iniciado em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
              <p class="my0 f-roboto fs-9pt fw-400" v-c:I v-if="atividade.status=='não iniciado'">
                <span>Início em</span> {{$moment(atividade.dataInicio).format("DD MMMM YYYY")}}
              </p>
            </v-col>
          </v-row>
        </v-timeline-item>
      </div>

    </v-timeline>

    <atividadetimelinedescr ref="atividadetimelinedescrref" />

  </div>
</template>

<script>
import { rdb, snapshotValToArray, snapshotValToArraySort } from '@/firebasedb.js';
import atividadetimelinedescr from "./atividadeTimeLineDescr.vue";

export default {
  name: "atividadetimeline",
  components: { atividadetimelinedescr },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      atividades: [],
      atividadesBlock: [],
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    rdb.ref('/curriculo/avaliacao/2021S2/atividades/').on('value',function(snapshot) {
      var atividades = snapshotValToArraySort(snapshot.val(),"dataInicio");
      self.atividades = [];
      self.atividadesBlock = [];
      for(var key in atividades) {
        if(atividades[key].status == "finalizado") {
          self.atividades.push(atividades[key]);
        }
      }
      for(var key in atividades) {
        if(atividades[key].status == "em andamento") {
          self.atividades.push(atividades[key]);
        }
      }
      for(var key in atividades) {
        if(atividades[key].status == "não iniciado") {
          self.atividadesBlock.push(atividades[key]);
        }
      }
      //console.log("self.atividades",self.atividades);
      self.build();
    });
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
