<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" lg="6">
          <listaatividades />
        </v-col>
        <v-col cols="12" lg="6">
          <v-card>
            <atividadetimeline />
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';
import atividadetimeline from "./atividadeTimeLine.vue"
import listaatividades from "./listaAtividades.vue"

export default {
  name: "atividades",
  components: { atividadetimeline, listaatividades },
  props: [],
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
