<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2 pt-0">
      <v-card-title class="fs-13pt my-0 pt-0 px-0 pb-0" v-c:I>
        <v-toolbar :color="cor.active.b">
          <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
            Cadastrar Atividades
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn outlined small :color="cor.active.V" @click="update">
            <i class="btn-icon-left" v-i:duo#check-circle#18></i>
            {{btnName}}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="mt-1">
            <v-col cols="12" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Nome da atividade</p>
              <v-text-field v-model="formData.nome" :rules="[rules.required]" solo dense required>
                <template v-slot:label>
                  <span class="f-sanspro fw-400 fs-11pt">
                    <i v-i:duo#keyboard#18 v-cHex="'#808080'"></i>
                    Digite o nome da atividade
                  </span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Descrição da atividade</p>
              <v-textarea v-model="formData.descricao" rows="2" :rules="[rules.required]" solo dense required>
                <template v-slot:label>
                  <span class="f-sanspro fw-400 fs-11pt">
                    <i v-i:duo#keyboard#18 v-cHex="'#808080'"></i>
                    Digite uma descrição para atividade
                  </span>
                </template>
              </v-textarea>
            </v-col>
            <v-col cols="12" lg="4" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Data de Início</p>
              <v-menu ref="menu1" v-model="menuDataInicio" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="dataInicioFormated" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formData.dataInicio" no-title @change="menuDataInicio = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Data de Término</p>
              <v-menu ref="menu1" v-model="menuDataFinal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :value="dataFinalFormated" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="formData.dataFinal" no-title @change="menuDataFinal = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="4" class="my-0 py-0">
              <p class="fw-300 fs-10pt f-roboto my-0 pb-1 ps-1">Status</p>
              <v-select solo dense v-model="formData.status" :items="[ 'em andamento','não iniciado', 'finalizado']">
                <template v-slot:selection="{ item, index }">
                  <span class="f-sanspro fs-10pt">
                    {{item}}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js'
import { eventBus } from "@/main.js";
import moment from "moment";

export default {
  name: "formevento",
  components: { },
  data() {
    return {
      menuDataInicio: false,
      menuDataFinal: false,
      refresh: 0,
      dialog: false,
      loading: false,
      btnName: "Adicionar",
      formData : { nome: "", descricao: "", dataFinal: "", dataInicio: "", status: "em andamento" },
      rules: {
        required: value => !!value || 'Obrigatório',
      },
    }
  },
  computed: {
    dataInicioFormated () {
      return this.formData.dataInicio ? moment(this.formData.dataInicio).format('DD/MM/YYYY') : ''
    },
    dataFinalFormated () {
      return this.formData.dataFinal ? moment(this.formData.dataFinal).format('DD/MM/YYYY') : ''
    },
  },
  watch: {
  },
  mounted: function() {
    var self = this;
    this.log("Mounted");
    this.loading = true;
    window.moment = moment;
  },

  methods:{

    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.nome,
      }
    },

    show(dados) {
      var self = this;
      console.log("dados",dados);
      this.dialog = true;
      this.refresh++;
      this.$forceUpdate();
      if(dados == null) {
        this.formData = { nome: "", descricao: "", dataFinal: "", dataInicio: "", status: "em andamento" };
        this.btnName = "Adicionar";
      } else {
        this.formData = clone2(dados);
        this.btnName = "Atualizar";
      }
      console.log("this.formData",this.formData);
    },

    update() {
      console.log("this.formData",this.formData);
      console.log("this.formData.DataInicio",this.formData.dataInicio);
      console.log("this.formData.dataFinal",this.formData.dataFinal);

      if(this.formData.nome == "") {
        this.toastD("Nome obrigatório!");
        return;
      }
      if(this.formData.descricao == "") {
        this.toastD("Descrição obrigatória!");
        return;
      }
      if(this.formData.dataInicio == "") {
        this.toastD("Datas obrigatórias!");
        return;
      }
      if(this.formData.dataFinal == "") {
        this.toastD("Datas obrigatórias!");
        return;
      }
      if(this.formData.status == null) {
        this.toastD("Status obrigatório!");
        return;
      }
      var formData = clone(this.formData);
      formData.dataFinal = moment(formData.dataFinal).format();
      formData.dataInicio = moment(formData.dataInicio).format();
      this.dialog = false;
      console.log(formData);
      this.$emit("update",{ formData: formData, tipo: this.btnName } );
    }

  }
}
</script>

<style scoped>

</style>
