<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card class="pb-2 pt-0">
      <v-card-title class="fs-13pt my-0 pt-0 px-0 mx-0 pb-0" v-c:I>
        <v-toolbar :color="cor.active.b">
          <v-btn class="ms-1 me-0 pe-0 ps-0" icon small v-bg:S#3 @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="ps-1 ms-1 f-sanspro fw-600 fs-11pt">
            <span class="me-2 f-sanspro fs-10pt fw-400">Atividade:</span>
            <span class="f-sanspro fs-11pt fw-600">{{atividade}}</span>
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container class="mb-0 pb-0 px-0">
          <p class="mb-1 ms-1 f-sanspro fs-9pt fw-600">Descrição</p>
          <v-card class="p-2 m-0" outlined>
            <p class="m-0 p-0 me-2 f-raleway fs-10pt fw-300">{{descricao}}</p>
          </v-card>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "atividadetimelinedescr",
  components: {},
  props: [],
  computed: {},
  data(){
    return {
      dialog: false,
      loading: false,
      refresh: 0,
      atividade: "",
      descricao: "",
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
  },
  methods:{

    build() {
      var self = this;
    },

    show(atividade,descricao) {
      this.atividade = atividade;
      this.descricao = descricao;
      this.dialog = true;
    }

  }
}
</script>

<style scoped>
</style>
