<template>
  <div class="m-0 p-0" v-if="atividade != null">
    <v-list-item class="">
      <v-list-item-icon class="mt-0 me-3">
        <i v-i:ic#task#26 v-cHex="'#808080'"></i>
      </v-list-item-icon>
      <v-list-item-content class="text-left pt-0">
        <v-list-item-title class="">
          <p class="mb-0 fs-10pt f-roboto">
            <span class="f-sanspro fw-600 fs-11pt">{{atividade.nome}}</span>
          </p>
        </v-list-item-title>
        <v-list-item-subtitle>
          <p class="my-0 fs-10pt f-roboto">
            <span class="fs-9pt f-roboto fw-400">
              <span class="ms-0 f-sanspro fw-800 fs-9pt">início:</span>
              <span class="ms-2">{{$moment(atividade.dataInicio).format("DD/MM/YYYY")}}</span>
              <span class="ms-4 f-sanspro fw-800 fs-9pt">Término:</span>
              <span class="ms-2">{{$moment(atividade.dataFinal).format("DD/MM/YYYY")}}</span>
              <span v-if="atividade.status == 'em andamento'" class="ms-4 px-1 f-sanspro fw-600 fs-9pt rounded" v-c:B v-bg:V>{{atividade.status}}</span>
              <span v-if="atividade.status == 'não iniciado'" class="ms-4 px-1 f-sanspro fw-600 fs-9pt rounded" v-c:B v-bg:I>{{atividade.status}}</span>
              <span v-if="atividade.status == 'finalizado'" class="ms-4 px-1 f-sanspro fw-600 fs-9pt rounded" v-c:B v-bg:D>{{atividade.status}}</span>
            </span>
          </p>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <p class="mb-2 fs-10pt f-roboto">
            <span class="fs-9pt f-raleway fw-400">
              {{atividade.descricao}}
            </span>
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon  class="px-0 mx-0">
        <v-btn class="px-0 mx-0" :color="cor.active.D" small icon @click="$emit('excluir',atividade)">
          <i class="btn-icon-left ps-1" v-i:duo#trash#20></i>
        </v-btn>
      </v-list-item-icon>
      <v-list-item-icon  class="px-0 mx-0 ms-1">
        <v-btn class="px-0 mx-0" :color="cor.active.I" small icon @click="$emit('editar',atividade)">
          <i class="btn-icon-left ps-1" v-i:duo#pencil#20></i>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>
    <v-divider class="mt-n3 mx-2"></v-divider>
  </div>
</template>

<script>
  import { rdb, snapshotValToArray } from '@/firebasedb.js';

  export default {
    name: "",
    components: {},
    props: [ "atividade" ],
    computed: {},
    data(){
      return {
        loading: false,
        refresh: 0,
      }
    },
    watch: {},
    mounted: function() {
      var self = this;
      //self.loading = true;
      //rdb.ref('').on('value',function(snapshot) {
      //  self.algo = snapshot.val();
      //  console.log("self.algo",self.algo);
      //  self.build();
      //});
    },
    methods:{

      build() {
          var self = this;
      }
    }
  }
</script>

<style scoped>
</style>
